.toolbar button {
    padding: 8px 12px;
    border: none;
    background-color: #f0f0f0;
    margin-right: 8px;
    cursor: pointer;
  }
  
  .toolbar button:hover {
    background-color: #e0e0e0;
  }
  
  .toolbar button.active {
    background-color: #0078d4;
    color: #fff;
  }
  
  .textInput {
    margin-bottom: 2em;
  }

  .accordionContainer {
    margin: 1em 0 1em 0;
    padding: 1.5em;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px -3px, rgba(0, 0, 0, 0.06) 0px 2px 10px -3px;
    position: relative;
  }

  .accordionNum {
    font-size: 1.2em;
    font-weight: bold;
    padding: 0.5em;
    position: absolute;
    top: 0;
    right: 22px;
    background-color: rgb(240, 240, 240);
    border-radius: 0 0 0.3em 0.3em;
  }

  .accordionBtnContainer {
    display: flex;
    width: auto;
    height: 38px;
    margin-bottom: 2em;
    gap: 1em;
  }

  .accordionWrapper {
    margin: 1em 0 0 0;
  }