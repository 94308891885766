
.courseActionContainer{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.courseContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid rgba(182, 181, 181, 0.693);
}

.courseContainer:hover{
    background-color: rgba(170, 184, 194, 0.3);
    transition: 0.2s;
}

.btnContainer{
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem; 
}

.courseContainerInactive{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid rgba(182, 181, 181, 0.693);
    opacity: 0.4;
}

.inactiveCourses{
    margin-top: 2rem;
}

.btnContainer button:hover{
   background-color: rgb(41,54,74);
   color: white;
   transition: 0.2s;
}
.btnStyle{
    width: 100%; 
    border-radius: 0.2rem;
    padding: 0.5rem;
    min-width: 175px !important;
}   

.del{
    border-color: red;
}

.courseTitle{
    display: inline !important;
}

.btnStyleDel{
    border-color: red;
}

.btnStyleAdd{
    border-color: green;
}