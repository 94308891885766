.pricePanel{
    font-size: 1.2rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input{
    width: 72%;
}

.container{
    margin-top: 2rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    overflow: auto;
    min-height: 0;
    margin-bottom: 150px;
}

.contentLearnContainer,
.contentRecieveContainer,
.contentShouldContainer,
.contentContainer,
.contentPurposeContainer,
.contentParticipantsContainer,
.contentMaterialContainer,
.contentIndledningContainer {
  display: flex;
  flex-direction: column;
  flex: 1; 
  min-height: 0;
}

.ms-TextField-wrapper {
    height: 100% !important;
  }

.inputContainer{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.priceInfoContainer{
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    justify-content: flex-end;
}

.btnSave{
    width: 100%;
}

.btnClose{
    width: 100%;
    background-color: "#f3f3f3";
}

.btnSaveContainer{
    display: flex;
    width: 95%;
    z-index: 10;
    position: fixed;
    background-color: white;
    padding: 2rem 0 2rem 0;
    bottom: 0;
    align-self: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px -20px 10px -20px;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.price{
    font-weight: bolder;
    color: rgb(41,54,74);
    margin-left: 0.5rem;

}

.dropdown {
    margin-bottom: 0.5em;
}

.courseTextField {
    width: 100%;
    margin-bottom: 0.5em;
}

.priceDurationContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
}

.courseCheckbox {
    margin-bottom: 0.5em;
}