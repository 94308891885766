.programContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1; 
    min-height: 0;
}

.programBtnContainer {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.programLinkText{
    font-size: 1.2em;
}

.btnAddCourse {
    width: 200px;
    padding: 0.5rem;
    border-radius: 0.2rem;
    /* right: 0;
    position: absolute;
    margin-right: 15px;
    z-index: 10; 
    justify-self: end;
    align-self: flex-end;*/
}

.programBtnContainer {
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    gap: 1rem;
    position: absolute;
    right: 0;
    margin-right: 15px;
    z-index: 10;
    align-self: flex-end;
}

.courseTypeHeader {
    position: relative;
    display: flex;
    flex-direction: row;
}

.programHeader{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.programHeaderFilter{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: flex-end;
}