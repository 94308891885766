.oversigtContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0 10px 0;
}

.priceContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;
    width: 20%;
}

.priceContainerLabel {
    font-weight: bolder;
    flex: 2;
}

.priceContainerContent {
    flex: 1;
    text-align: end;
    color: rgb(75, 139, 0);
    font-weight: bolder;
}

.title {
    background-color: rgb(87, 121, 122);
    color: white;
    padding: 1rem;
    font-weight: bolder;

}

.content {
    background-color: rgb(245, 245, 245);
}

@media only screen and (max-width: 1200px) {
    .priceContainer {
        width: 100%;
    }

    .priceContainerLabel {
        flex: 1 !important;
    }
    
    .priceContainerContent {
        flex: 2 !important;
        text-align: end;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1900px) {
    .priceContainer {
        width: 35%;
    }
}