.filters {
    width: 100%;
    margin: 10px 0 40px 0;
    display: flex;
    flex-direction: row;
    text-align: center;
}

.tableHeader {
    color: white !important;
    font-weight: 700 !important;
    font-size: 1rem !important;
}

.filter {
    width: 200px;
    max-height: 40px;
    margin: 0 10px 0 10px;
}

.submitBtn{
    margin: 0 10px 0 10px !important;
    height: 40px;
    cursor: pointer;
}